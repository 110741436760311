import * as React from "react"
import { Layout } from "@bw/layouts"
import { BecomeAnArtist } from "@bw/partials"

const BecomeACreator = ({ pageContext }) => {
  return (
    <Layout {...{ pageContext }}>
      <BecomeAnArtist />
    </Layout>
  )
}

export default BecomeACreator
